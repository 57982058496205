import React, { useEffect, useState } from 'react'
import Box from '../RadioUI/Box'
import Text from '../RadioUI/Text'
import Icon from '../RadioUI/Icon'
import PropTypes from 'prop-types'
import Portal from '../RadioUI/Portal'
import { useColors } from '../RadioUI/theme/Color'

const noop = () => {}
const defaultToastIcon = 'nrk-check-active'

const Toast = ({
  isHidden,
  setIsHidden,
  children,
  onClick = noop,
  iconId = defaultToastIcon,
  isWarning = false,
  duration = 5000,
  ...restProps
}) => {
  const [showToast, setShowToast] = useState(false)
  const colors = useColors()

  useEffect(() => {
    let timer, showTimer, hideTimer
    if (!isHidden) {
      timer = setTimeout(() => {
        setIsHidden(true)
      }, duration + 100)
      showTimer = setTimeout(() => {
        setShowToast(true)
      }, 10)
      hideTimer = setTimeout(() => {
        setShowToast(false)
      }, duration)
    }
    return () => {
      clearTimeout(timer)
      clearTimeout(showTimer)
      clearTimeout(hideTimer)
    }
  }, [duration, isHidden, setIsHidden])

  if (isHidden) return null

  return (
    <Portal aria-live="polite">
      <Box
        maxW="90%"
        w="max-content"
        top="0"
        left="50%"
        pos="fixed"
        zIndex="toast"
        transform="translateX(-50%)"
        {...restProps}
      >
        <Box
          background={isWarning ? colors.warning.light : colors.main.light}
          color={isWarning ? colors.warning.dark : colors.main.dark}
          onClick={() => {
            onClick()
            setIsHidden(true)
          }}
          pointerEvents="auto"
          padding="12px 16px"
          borderRadius="6px"
          cursor="pointer"
          willChange="transform, opacity"
          transition="opacity 0.1s cubic-bezier(0.12, 0, 0, 1), transform 0.1s linear"
          {...(showToast
            ? { opacity: 1, transform: 'translate(0, 20%)' }
            : { opacity: 0, transform: 'translate(0, -20%)' })}
        >
          <Box d="flex" alignItems="center">
            {iconId && <Icon iconId={isWarning && iconId === defaultToastIcon ? 'nrk-404' : iconId} mr="9px" />}
            <Text size="sm" weight="semibold">
              {children}
            </Text>
          </Box>
        </Box>
      </Box>
    </Portal>
  )
}

Toast.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  setIsHidden: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  iconId: PropTypes.string,
  isWarning: PropTypes.bool,
  duration: PropTypes.number
}

export default Toast
