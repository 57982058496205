import { seriesRootSelector } from './seriesHooks'
import { createSelector } from 'reselect'
import useShallowSelector from '../../../components/hooks/useShallowSelector'
import { useSelector } from 'react-redux'
import _get from 'lodash/get'
import toSrcSet from '../helpers/toSrcSet'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { progressesSelector } from '../../../redux/personalisation/progressHooks'
import PROGRESS_STATES from '../../../redux/personalisation/helpers/progressStates'
import { useIsLoggedIn, useSub } from '../../../components/Auth/AuthContext'

const emptyObject = {}
const emptyArray = []

export const seasonSelector = createSelector([seriesRootSelector], seriesRoot => {
  return seriesRoot.season
})

export const useSeason = () => {
  const season = useShallowSelector(seasonSelector)
  return season
}

const seasonIsLoadingSelector = createSelector([seasonSelector], season => !!season.loading)

export const useSeasonIsLoading = () => {
  const isLoading = useSelector(seasonIsLoadingSelector)
  return isLoading
}

const seasonHrefSelector = createSelector([seasonSelector], season => _get(season, '_links.self.href'))

export const useSeasonHref = () => {
  const seasonHref = useSelector(seasonHrefSelector)
  return seasonHref
}

const seasonTitleSelector = createSelector([seasonSelector], season => _get(season, 'titles.title'))

export const useSeasonTitle = () => {
  const seasonTitle = useSelector(seasonTitleSelector)
  return seasonTitle
}

const seasonSubtitleSelector = createSelector([seasonSelector], season => _get(season, 'titles.subtitle'))

export const useSeasonSubtitle = () => {
  const subtitle = useSelector(seasonSubtitleSelector)
  return subtitle
}

const seasonImageRawSelector = createSelector([seasonSelector], season => season.image)

export const useSeasonImageRaw = () => {
  const seasonImageArr = useShallowSelector(seasonImageRawSelector)
  return seasonImageArr
}
const seasonBackdropImageRawSelector = createSelector([seasonSelector], season => season.backdropImage)

export const useSeasonBackdropImageRaw = () => {
  const seasonImageArr = useShallowSelector(seasonBackdropImageRawSelector)
  return seasonImageArr
}

const seasonImageSelector = createSelector([seasonImageRawSelector], image => {
  if (!image || image.length < 1) return emptyObject

  const { url } = image.find(({ width }) => width > 960) || {}
  const srcSet = toSrcSet(image)
  return { src: url, srcSet }
})

export const useSeasonImage = () => {
  const seasonImage = useShallowSelector(seasonImageSelector)
  return seasonImage
}

const seasonBackdropImageSelector = createSelector([seasonBackdropImageRawSelector], image => {
  if (!image || image.length < 1) return emptyObject

  const { url } = image.find(({ width }) => width > 960) || {}
  const srcSet = toSrcSet(image)
  return { src: url, srcSet }
})

export const useSeasonBackdropImage = () => {
  const seasonImage = useShallowSelector(seasonBackdropImageSelector)
  return seasonImage
}

const seasonEpisodesProgressHrefSelector = createSelector([seasonSelector], season => {
  const progressHref = _get(season, '_embedded.episodes._links.progresses[0].href')
  if (!progressHref) return null
  return progressHref
})

const seasonEpisodesSelector = createSelector([seasonSelector, progressesSelector], (season, progresses) => {
  const seasonEpisodes = _get(season, '_embedded.episodes._embedded.episodes', emptyArray).map(episode => {
    const progress = progresses.find(progress => progress.episodeId === episode.episodeId)
    if (progress) {
      const progressState = progress.progress
      const percentage = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.percentage`)
      const startPoint = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.timeInSeconds`)
      const finished = _get(progress, 'finished', false)
      return { ...episode, progressState, percentage, startPoint, finished }
    }
    return episode
  })

  return seasonEpisodes
})

export const useSeasonEpisodes = () => {
  const seasonEpisodes = useShallowSelector(seasonEpisodesSelector)
  return seasonEpisodes
}

const seasonEpisodeCountSelector = createSelector(
  [seasonEpisodesSelector],
  seasonEpisodes => seasonEpisodesSelector.length
)

export const useSeasonEpisodeCount = () => {
  const seasonEpisodeCount = useSelector(seasonEpisodeCountSelector)
  return seasonEpisodeCount
}

const seasonEpisodesIsLoadingSelector = createSelector([seasonSelector], season => season.episodesLoading)

export const useSeasonEpisodesIsLoading = () => {
  const isLoading = useSelector(seasonEpisodesIsLoadingSelector)
  return isLoading
}

const latestEpisodesRootSelector = createSelector([seriesRootSelector], seriesRoot => {
  return _get(seriesRoot, 'latestEpisodes', emptyObject)
})

const latestEpisodesSelector = createSelector(
  [latestEpisodesRootSelector, progressesSelector],
  (latestEpisodesRoot, progresses) => {
    const episodes = _get(latestEpisodesRoot, 'episodes', emptyArray).map(episode => {
      const progress = progresses.find(progress => progress.episodeId === episode.episodeId)
      if (progress) {
        const progressState = progress.progress
        const percentage = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.percentage`)
        const startPoint = _get(progress, `${PROGRESS_STATES.IN_PROGRESS}.timeInSeconds`)
        const finished = _get(progress, 'finished', false)
        return { ...episode, progressState, percentage, startPoint, finished }
      }
      return episode
    })
    return episodes
  }
)

export const useLatestEpisodes = () => {
  const latestEpisodes = useShallowSelector(latestEpisodesSelector)
  return latestEpisodes
}

const latestEpisodesProgressHrefSelector = createSelector([latestEpisodesRootSelector], latestEpisodesRoot => {
  const latestEpisodesProgressHref = _get(latestEpisodesRoot, '_links.progresses[0].href')
  if (!latestEpisodesProgressHref) return null

  return latestEpisodesProgressHref
})

const latestEpisodesLoadingSelector = createSelector(
  [latestEpisodesRootSelector],
  latestEpisodesRoot => latestEpisodesRoot.loading
)

export const useLatestEpisodesIsLoading = () => {
  const isLoading = useSelector(latestEpisodesLoadingSelector)
  return isLoading
}

const showSeasonNotLatestSelector = (_, params) => !!params.seasonId

const makeShowSeasonNotLatestSelector = () =>
  createSelector([showSeasonNotLatestSelector], showSeasonNotLatest => showSeasonNotLatest)

export const useShowSeasonNotLatest = () => {
  const params = useParams()
  const selectShowSeason = useMemo(makeShowSeasonNotLatestSelector, [])
  const showSeasonNotLatest = useSelector(state => selectShowSeason(state, params))
  return showSeasonNotLatest
}

const makeEpisodesSelector = () =>
  createSelector(
    [seasonEpisodesSelector, latestEpisodesSelector, showSeasonNotLatestSelector],
    (seasonEpisodes, latestEpisodes, showSeason) => {
      if (showSeason) {
        return seasonEpisodes
      }

      return latestEpisodes
    }
  )

export const useEpisodes = () => {
  const params = useParams()
  const selectEpisodes = useMemo(makeEpisodesSelector, [])
  const episodes = useShallowSelector(state => selectEpisodes(state, params))
  return episodes
}

const makeEpisodesProgressHrefSelector = () =>
  createSelector(
    [seasonEpisodesProgressHrefSelector, latestEpisodesProgressHrefSelector, showSeasonNotLatestSelector],
    (seasonEpisodesProgressHref, latestEpisodesProgressHref, showSeasonNotLatest) => {
      if (showSeasonNotLatest) {
        return seasonEpisodesProgressHref
      }
      return latestEpisodesProgressHref
    }
  )

const latestEpisodesNextPageHrefSelector = createSelector([latestEpisodesRootSelector], latestEpisodesRoot => {
  const nextPageHref = _get(latestEpisodesRoot, '_links.next.href')
  return nextPageHref
})

const seasonEpisodesNextPageHrefSelector = createSelector([seasonSelector], season => {
  const nextPageHref = _get(season, '_embedded.episodes._links.next.href')
  return nextPageHref
})

const makeEpisodesNextPageHrefSelector = () =>
  createSelector(
    [latestEpisodesNextPageHrefSelector, seasonEpisodesNextPageHrefSelector, showSeasonNotLatestSelector],
    (latestEpisodesNextPageHref, seasonEpisodesNextPageHref, showSeasonNotLatest) => {
      if (showSeasonNotLatest) {
        return seasonEpisodesNextPageHref
      }
      return latestEpisodesNextPageHref
    }
  )

export const useEpisodesNextPageHref = () => {
  const params = useParams()
  const selectEpisodesNextPageHref = useMemo(makeEpisodesNextPageHrefSelector, [])
  const episodeNextPageHref = useSelector(state => selectEpisodesNextPageHref(state, params))
  return episodeNextPageHref
}
export const useSeasonEpisodesNextPageHref = () => {
  const nextPageHref = useSelector(seasonEpisodesNextPageHrefSelector)
  return nextPageHref
}

export const useEpisodesProgressHref = () => {
  const params = useParams()
  const selectProgressHref = useMemo(makeEpisodesProgressHrefSelector, [])
  const progressHref = useSelector(state => selectProgressHref(state, params))
  const isLoggedIn = useIsLoggedIn()
  const userId = useSub()

  if (isLoggedIn && progressHref) {
    return progressHref.replace('{userId}', userId)
  }

  return null
}
export const useNumberOfEpisodes = () => {
  const episodes = useEpisodes()

  return Array.isArray(episodes) ? episodes.length : 0
}

const seasonExtraMaterialHrefSelector = createSelector([seasonSelector], season => {
  const extraMaterialHref = season?._links?.extraMaterial?.href
  return extraMaterialHref
})

export const useSeasonExtraMaterialHref = () => {
  const extraMaterialHref = useSelector(seasonExtraMaterialHrefSelector)
  return extraMaterialHref
}

export const useSeasonHasExtraMaterial = () => {
  const extraMaterialHref = useSelector(seasonExtraMaterialHrefSelector)
  return !!extraMaterialHref
}

const totalSeasonEpisodeCountSelector = createSelector([seasonSelector], season => season.episodeCount)

export const useTotalSeasonEpisodeCount = () => {
  const seasonEpisodeCount = useSelector(totalSeasonEpisodeCountSelector)
  return seasonEpisodeCount
}

const episodesPageIsLoadingSelector = createSelector(
  [seasonSelector, latestEpisodesRootSelector],
  (season, latestEpisodesRoot) => season.seasonPageLoading || latestEpisodesRoot.latestEpisodesPageLoading
)

export const useEpisodesPageIsLoading = () => {
  const isLoading = useSelector(episodesPageIsLoadingSelector)
  return isLoading
}

const firstOfNextPageSelector = createSelector([seriesRootSelector], seriesRoot => seriesRoot.firstOfNextPage)

export const useFirsOfNextPage = () => {
  const episodeId = useSelector(firstOfNextPageSelector)
  return episodeId
}
