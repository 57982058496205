import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import {
  useSeriesTitle,
  useSeriesIsLoading,
  useSeriesSubtitle,
  useSeriesImageRaw,
  useSeriesImageSquareRaw
} from './hooks/seriesHooks'
import { useSeasonTitle, useSeasonIsLoading, useSeasonImageRaw, useSeasonBackdropImageRaw } from './hooks/seasonHooks'
import { useEpisodeTitle, useEpisodeIsLoading, useEpisodeDescription, useEpisodeImageRaw } from './hooks/episodeHooks'

const NO_INDEX_PROGRAM_IDS = ['l_e9e9d888-d92d-4683-a9d8-88d92d56835e']

export const SeriesMeta = ({ children }) => {
  const params = useParams()
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()
  const seriesTitle = useSeriesTitle()
  const seasonTitle = useSeasonTitle()
  const episodeTitle = useEpisodeTitle()
  const seriesDescription = useSeriesSubtitle()
  const episodeDescription = useEpisodeDescription()
  const seriesImageSquare = useSeriesImageSquareRaw()
  const seriesImages = useSeriesImageRaw()
  const seasonImages = useSeasonImageRaw()
  const episodeImages = useEpisodeImageRaw()
  const seasonBackdropImages = useSeasonBackdropImageRaw()

  const getTitle = useCallback(() => {
    const baseTitle = 'NRK Radio'
    const seriesTitlePart = !seriesIsLoading && !!seriesTitle ? ` - ${seriesTitle}` : ''
    if (params.programId && !episodeIsLoading && !seriesIsLoading) {
      return `${episodeTitle}${seriesTitlePart} - ${baseTitle}`
    } else if (params.seasonId && !seasonIsLoading && !seriesIsLoading) {
      return `${seasonTitle} - ${seriesTitle} - ${baseTitle}`
    } else if (!seriesIsLoading) {
      return `${seriesTitle} - ${baseTitle}`
    } else {
      return baseTitle
    }
  }, [
    episodeIsLoading,
    episodeTitle,
    params.programId,
    params.seasonId,
    seasonIsLoading,
    seasonTitle,
    seriesIsLoading,
    seriesTitle
  ])

  const getDescription = useCallback(() => {
    if (params.programId) {
      return episodeIsLoading ? null : episodeDescription
    } else if (params.seasonId) {
      return seriesIsLoading || seasonIsLoading ? null : `${seriesTitle} - ${seasonTitle}`
    }
    return seriesIsLoading ? null : seriesDescription
  }, [
    episodeDescription,
    episodeIsLoading,
    params.programId,
    params.seasonId,
    seasonIsLoading,
    seasonTitle,
    seriesDescription,
    seriesIsLoading,
    seriesTitle
  ])

  const getAllSeriesImages = useCallback(() => {
    const seriesImageSquareWithHeight = Array.isArray(seriesImageSquare)
      ? seriesImageSquare.map(image => {
          return { ...image, height: image.width }
        })
      : []

    const seriesImagesMergeSafe = seriesImages || []

    return [...seriesImageSquareWithHeight, ...seriesImagesMergeSafe]
  }, [seriesImageSquare, seriesImages])

  const getImages = useCallback(() => {
    if (seriesIsLoading || seasonIsLoading || episodeIsLoading) return null
    const allSeriesImages = getAllSeriesImages()
    if (params.programId) {
      return episodeImages || seasonBackdropImages || seasonImages || allSeriesImages
    } else if (params.seasonId) {
      return seasonBackdropImages || seasonImages || allSeriesImages
    }
    return allSeriesImages
  }, [
    episodeImages,
    episodeIsLoading,
    getAllSeriesImages,
    params.programId,
    params.seasonId,
    seasonBackdropImages,
    seasonImages,
    seasonIsLoading,
    seriesIsLoading
  ])

  const title = getTitle()
  const description = getDescription()
  const images = getImages()

  return (
    <>
      <Helmet>
        <meta property="og:title" content={title} />
        <title>{title}</title>
        {description && <meta property="og:description" content={description} />}
        {description && <meta name="description" content={description} />}
        {NO_INDEX_PROGRAM_IDS.includes(params.programId) && <meta name="robots" content="noindex"></meta>}
      </Helmet>
      {Array.isArray(images) &&
        images.map(({ url, width, height }) => {
          return (
            <Helmet key={`${url}_${width || 'noWidth'}_${height || 'noHeight'}`}>
              <meta property="og:image" content={url} />
              {width && <meta property="og:image:width" content={width} />}
              {height && <meta property="og:image:height" content={height} />}
            </Helmet>
          )
        })}
      {children}
    </>
  )
}

SeriesMeta.propTypes = {
  children: PropTypes.node
}

export const withSeriesMeta = WrappedComponent => {
  const WithSeriesMeta = props => {
    return (
      <SeriesMeta>
        <WrappedComponent {...props} />
      </SeriesMeta>
    )
  }
  return WithSeriesMeta
}

export default SeriesMeta
